const data = [
  {
    id: 0,
    img: '/services/dev.jpg',
    title: 'Software Development',
    description:
      'Web, mobile or custom development. From product ideation to architecture and implementation. We build custom products that solve your specific problems.'
  },
  {
    id: 1,
    img: '/services/uxui.jpg',
    title: 'UX & UI Product Design',
    description:
      'User centered design, solving today tomorrow’s problems. UX/UI audit, user research, user testing, design systems, design thinking and much more.'
  },
  {
    id: 2,
    img: '/services/popm.jpg',
    title: 'Product Strategy & Consulting',
    description:
      'The Digital world has numerous possibilities and can be  overwhelming, whether you need a Growth Strategy, CRM, SEO audit, automations or other, we can help you accelerate your product. '
  }
];

export default data;
