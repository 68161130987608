const data = [
  {
    id: 0,
    logo: 'bab',
    name: 'Barbearia à Benfica'
    // link: 'https://barbeariaabenfica.com/'
  },
  {
    id: 1,
    logo: 'decathlon',
    name: 'Decathlon'
    // link: 'https://decathlon.pt'
  },
  {
    id: 2,
    logo: 'who',
    name: 'World Health Organization'
    // link: 'https://www.who.int'
  },
  {
    id: 3,
    logo: 'fr-gov',
    name: 'French Government'
    // link: 'https://decathlon.pt'
  },
  {
    id: 4,
    logo: 'public-service',
    name: 'Public Serv-ce'
    // link: 'https://decathlon.pt'
  },
  {
    id: 5,
    logo: 'portela',
    name: 'Portela Cafés'
    // link: 'https://portelacafes.pt'
  },
  {
    id: 6,
    logo: 'wgh',
    name: 'Women in Global Health'
    // link: 'https://decathlon.pt'
  },
  {
    id: 7,
    logo: 'unii',
    name: 'Unii Organic'
    // link: 'https://uniiorganic.com'
  }
];

export default data;
