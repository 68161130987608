import styled from 'styled-components';

// import { theme } from '../../Subatoms/Theme';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(${({ imgURL }) => process.env.PUBLIC_URL + imgURL});
  background-size: cover;
  background-position: center center;
  z-index: -1;
  opacity: ${({ opacity }) => opacity};
`;

export default Wrapper;
