import styled from 'styled-components';

import { theme } from '../../Subatoms/Theme';

const Wrapper = styled.button`
  position: relative;
  border: none;
  background-color: transparent;

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 32px;
    height: 32px;
    background: ${theme.colors.black.default};
    border-radius: 50%;
    animation: pulse-border 2500ms ease-out infinite;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 32px;
    height: 32px;
    background: ${theme.colors.black.darker};
    border-radius: 50%;
    transition: all 200ms;
  }

  &:hover {
    cursor: pointer;
  }

  & > div {
    position: relative;
    z-index: 9;
  }

  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }
`;

export default Wrapper;
