import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';

import Instagram from './Icons/Instagram';
import Linkedin from './Icons/Linkedin';
import Plus from './Icons/Plus';
import Minus from './Icons/Minus';

const Icon = (props) => {
  const {
    type,
    link,
    fill,
    hoveredFill,
    stroke,
    hoveredStroke,
    strokeWidth,
    hoveredStrokeWidth
  } = props;

  const [hovered, setHovered] = useState(false);

  const getIcon = (type) => {
    switch (type) {
      case 'instagram':
        return (
          <Instagram
            fill={!hovered ? fill : hoveredFill}
            stroke={!hovered ? stroke : hoveredStroke}
            strokeWidth={!hovered ? strokeWidth : hoveredStrokeWidth}
          />
        );
      case 'linkedin':
        return (
          <Linkedin
            fill={!hovered ? fill : hoveredFill}
            stroke={!hovered ? stroke : hoveredStroke}
            strokeWidth={!hovered ? strokeWidth : hoveredStrokeWidth}
          />
        );
      case 'plus':
        return (
          <Plus
            fill={!hovered ? fill : hoveredFill}
            stroke={!hovered ? stroke : hoveredStroke}
            strokeWidth={!hovered ? strokeWidth : hoveredStrokeWidth}
          />
        );
      case 'minus':
        return (
          <Minus
            fill={!hovered ? fill : hoveredFill}
            stroke={!hovered ? stroke : hoveredStroke}
            strokeWidth={!hovered ? strokeWidth : hoveredStrokeWidth}
          />
        );
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Wrapper {...props}>
      {link ? (
        <a
          href={link}
          className="icon-link"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          target="_blank"
          rel="noreferrer"
        >
          {getIcon(type)}
        </a>
      ) : (
        getIcon(type)
      )}
    </Wrapper>
  );
};

Icon.defaultProps = {
  width: '24px',
  height: '24px',
  strokeWidth: '1',
  margin: '0',
  borderRadius: '0',
  padding: '0'
};

Icon.propTypes = {
  type: PropTypes.string,
  link: PropTypes.string,
  fill: PropTypes.string,
  hoveredFill: PropTypes.string,
  stroke: PropTypes.string,
  hoveredStroke: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  strokeWidth: PropTypes.string,
  hoveredStrokeWidth: PropTypes.string,
  bgColor: PropTypes.string,
  margin: PropTypes.string,
  borderRadius: PropTypes.string,
  padding: PropTypes.string
};

export default Icon;
