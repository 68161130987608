import styled from 'styled-components';

import { theme } from '../../Subatoms/Theme';

const Wrapper = styled.div`
  background: white;
  border-radius: 100px;
  border: 1px solid #d5d7d9;

  &.badge-small {
    padding: ${theme.spacing * 0.5}px ${theme.spacing * 1}px;
    font-size: 1rem;
    line-height: 2rem;
  }

  &.badge-medium {
    padding: ${theme.spacing * 1}px ${theme.spacing * 2}px;
    font-size: 1.2rem;
    line-height: 2.2rem;
  }

  &.badge-large {
    padding: ${theme.spacing * 1.5}px ${theme.spacing * 3}px;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
`;

export default Wrapper;
