import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';

const Flex = (props) => {
  return <Wrapper {...props}>{props.children}</Wrapper>;
};

Flex.defaultProps = {
  width: { default: '100%' },
  height: { default: 'unset' }
};

Flex.propTypes = {
  children: PropTypes.node,
  flexDirection: PropTypes.object,
  justifyContent: PropTypes.object,
  alignItems: PropTypes.object,
  wrap: PropTypes.object,
  width: PropTypes.object,
  height: PropTypes.object,
  gap: PropTypes.object,
  margin: PropTypes.object,
  padding: PropTypes.object
};

export default Flex;
