import React from 'react';
import PropTypes from 'prop-types';

import { theme } from '../../Subatoms/Theme';
import Wrapper from './Wrapper';

import Typography from '../../Subatoms/Typography';
import Flex from '../../Atoms/Flex';

const Hero = (props) => {
  return (
    <Wrapper {...props}>
      <Flex
        maxWidth={{ default: '1440px' }}
        margin={{ default: '0 auto' }}
        height={{ default: '100%' }}
      >
        <Flex
          flexDirection={{ default: 'column' }}
          justifyContent={{ default: 'center' }}
          alignItems={{ default: 'center' }}
        >
          <Flex
            width={{ default: '80%', md: '60%' }}
            flexDirection={{ default: 'column' }}
            justifyContent={{ default: 'flex-start' }}
            gap={{ default: '10px' }}
          >
            <Typography
              type="display"
              text="Your digital"
              color={theme.colors.white.default}
              align={{ default: 'left' }}
            />
            <Typography
              type="display"
              text="transformation"
              color={theme.colors.white.default}
              align={{ default: 'right' }}
            />
            <Typography
              type="display"
              text="starts here."
              color={theme.colors.white.default}
              align={{ default: 'center' }}
            />
            <Flex
              justifyContent={{ default: 'center' }}
              margin={{ default: `${theme.spacing * 5}px 0 0` }}
            >
              <Typography
                type="p"
                text="We are experts in creating, building and growing digital products."
                color={theme.colors.white.default}
                align={{ default: 'center' }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <div
        style={{ position: 'absolute', bottom: '0', left: '0', width: '100%' }}
        className="hero-footer"
      >
        <Flex
          flexDirection={{ default: 'column', sm: 'row' }}
          justifyContent={{ default: 'space-between' }}
          maxWidth={{ default: '1440px' }}
          margin={{
            default: `${theme.spacing * 3}px`,
            sm: `${theme.spacing * 5}px ${theme.spacing * 8}px`,
            xl: `${theme.spacing * 5}px auto`
          }}
          width={{ default: 'unset' }}
        >
          <Typography
            type="p"
            text="Our Values are our innegociable fingerprint"
            color={theme.colors.white.default}
            align={{ default: 'center', sm: 'left' }}
          />
          <Typography
            type="p"
            text="Innovation | Agility | Collaboration"
            color={theme.colors.white.default}
            align={{ default: 'center', sm: 'right' }}
          />
        </Flex>
      </div>
    </Wrapper>
  );
};

Hero.defaultProps = {
  height: { default: '900px' }
};

Hero.propTypes = {
  height: PropTypes.object
};

export default Hero;
