import styled from 'styled-components';

import { theme } from '../../Subatoms/Theme';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  ${({ height }) =>
    height && `height: ${height.xs ? height.xs : height.default};`}
  background: linear-gradient(
    180deg,
    ${theme.colors.secondary.default} 0%,
    ${theme.colors.primary.default} 100%
  );
  border-radius: 30px;
  color: ${theme.colors.white.default};

  & .dynamic-content span {
    transition: width 0ms ease-in-out;
  }

  & .dynamic-content span.is-selected {
    transition: width 750ms ease-in-out;
  }

  @media ${theme.responsiveCSSRules.sm} {
    ${({ height }) => height && height.sm && `height: ${height.sm};`}}
  }

  @media ${theme.responsiveCSSRules.md} {
    ${({ height }) => height && height.md && `height: ${height.md};`}}
  }

  @media ${theme.responsiveCSSRules.lg} {
    ${({ height }) => height && height.lg && `height: ${height.lg};`}}
  }

  @media ${theme.responsiveCSSRules.xl} {
    ${({ height }) => height && height.xl && `height: ${height.xl};`}}
  }
`;

export default Wrapper;
