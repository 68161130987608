/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';

const Mansory = (props) => {
  const { data, reviews, height, columns } = props;
  const Desktop = useMediaQuery('(min-width:1000px)');
  const Ipad = useMediaQuery('(min-width:700px)');

  return (
    <Masonry
      columns={Desktop ? (columns ? columns : 4) : Ipad ? 2 : 1}
      spacing={Desktop ? 2 : Ipad ? 1 : 1}
    >
      <Paper
        sx={{
          padding: '2rem',
          backgroundColor: '#5b93fc',
          color: '#fff'
        }}
      >
        <Stack spacing={1}>
          <Stack
            direction="row"
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem'
            }}
          >
            <Typography variant="h3">{data.rating}</Typography>
            <Box>
              <StarIcon fontSize="large" />
            </Box>
          </Stack>
          <Box>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
              {data.name}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
              {data.user_ratings_total}&nbsp;reviews
            </Typography>
          </Box>
        </Stack>
      </Paper>
      {reviews.map((review, i) => (
        <Paper
          key={i}
          sx={{ padding: '2rem', overflow: 'scroll', maxHeight: `${height}px` }}
        >
          <Stack spacing={1}>
            <Stack direction="row" sx={{ alignItems: 'center', gap: '1rem' }}>
              <Box>
                <Avatar
                  alt={review.author_name}
                  src={review.profile_photo_url}
                />
              </Box>
              <Box>{review.author_name}</Box>
            </Stack>
            <Box>
              <Rating name="read-only" value={review.rating} readOnly />
            </Box>
            <Box>
              <Typography variant="body1" gutterBottom>
                {review.text}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" display="block">
                {review.relative_time_description}
              </Typography>
            </Box>
          </Stack>
        </Paper>
      ))}
    </Masonry>
  );
};

Mansory.defaultProps = {};

Mansory.propTypes = {
  data: PropTypes.object,
  reviews: PropTypes.array,
  height: PropTypes.string,
  columns: PropTypes.number
};

export default Mansory;
