import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';

const Typography = (props) => {
  const { type, text } = props;
  return (
    <Wrapper
      as={type === 'display' ? 'h2' : type === 'caption' ? 'p' : type}
      className={type}
      {...props}
    >
      {text}
    </Wrapper>
  );
};

Typography.defaultProps = {
  type: 'p',
  text: 'Lorem Ipsum',
  align: { default: 'left' },
  color: '#000'
};

Typography.propTypes = {
  type: PropTypes.oneOf([
    'display',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'span',
    'caption'
  ]),
  text: PropTypes.string,
  align: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.object
};

export default Typography;
