import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';

import Box from '@mui/material/Box';

import Wrapper from './Wrapper';
import { Mansory, Slider } from './Views';

const GoogleReviews = (props) => {
  let { placeId } = useParams();
  let [searchParams] = useSearchParams();
  const type = searchParams.get('type') ? searchParams.get('type') : 'slider';
  const [data, setData] = useState();
  const [reviews, setReviews] = useState();
  // const googlePlacesURL = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`;
  const googlePlacesURL =
    'https://3bx2qrch0e.execute-api.eu-central-1.amazonaws.com/thecoded_server_api--main/google/reviews';

  const getPlaceReviews = async () => {
    try {
      const res = await axios({
        method: 'GET',
        url: googlePlacesURL,
        params: {
          placeId
        }
      });
      setReviews(res.data.reviews);
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!reviews) getPlaceReviews();
  }, [reviews]);

  if (!data) return;

  return (
    <Wrapper
      {...props}
      style={{
        width: `${searchParams.get('width')}px`,
        height: searchParams.get('height')
          ? `${searchParams.get('height')}px`
          : 'unset'
      }}
    >
      <Box
        sx={{
          width: `${searchParams.get('width')}px`,
          height: searchParams.get('height')
            ? `${searchParams.get('height')}px`
            : 'unset'
        }}
      >
        {type === 'mansory' ? (
          <Mansory
            data={data}
            reviews={reviews}
            height={searchParams.get('height')}
            columns={Number(searchParams.get('columns'))}
          />
        ) : (
          <Slider
            data={data}
            reviews={reviews}
            height={searchParams.get('height')}
            columns={Number(searchParams.get('columns'))}
          />
        )}
      </Box>
    </Wrapper>
  );
};

export default GoogleReviews;
