import styled from 'styled-components';

import { theme } from '../../Subatoms/Theme';

const Wrapper = styled.div`
  width: 100%;
  ${({ height }) =>
    height && `height: ${height.xs ? height.xs : height.default};`}
  position: relative;
  background-color: #26292e;
  border-radius: 0px 0px 30px 30px;

  & .display:nth-of-type(2) {
    background: linear-gradient(
      90deg,
      ${theme.colors.secondary.default} 0%,
      ${theme.colors.primary.default} 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & .hero-footer:before {
    position: absolute;
    top: 0;
    left: ${theme.spacing * 3}px;
    width: calc(100% - ${theme.spacing * 3 * 2}px);
    content: '';
    height: 1px;
    background-color: white;
  }

  @media ${theme.responsiveCSSRules.sm} {
    ${({ height }) => height && height.sm && `height: ${height.sm};`}

    & .hero-footer:before {
      left: ${theme.spacing * 8}px;
      width: calc(100% - ${theme.spacing * 8 * 2}px);
    }
  }

  @media ${theme.responsiveCSSRules.md} {
    ${({ height }) => height && height.md && `height: ${height.md};`}
  }

  @media ${theme.responsiveCSSRules.lg} {
    ${({ height }) => height && height.lg && `height: ${height.lg};`}
  }
`;

export default Wrapper;
