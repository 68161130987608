import styled from 'styled-components';

// import { theme } from '../../Subatoms/Theme';

const Wrapper = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-width: ${({ width }) => width};
  min-height: ${({ height }) => height};
  ${({ bgColor }) => bgColor && ` background-color: ${bgColor}`};
  border-radius: ${({ borderRadius }) => borderRadius};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};

  background-color: & a.icon-link {
    display: block;
    width: 100%;
    height: 100%;
  }

  & a.icon-link svg {
    transition: all 300ms ease-in-out;
  }
`;

export default Wrapper;
