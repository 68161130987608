import styled from 'styled-components';

// import { theme } from '../../Subatoms/Theme';

const Wrapper = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
  display: flex;
  justify-content: center;
  align-items: center;

  & a.icon-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  & a.icon-link svg {
    transition: all 300ms ease-in-out;
  }

  & svg {
    filter: grayscale(1) opacity(0.3);
    transition: all 600ms ease-in-out;
  }

  & svg:hover {
    filter: grayscale(0) opacity(1);
    transition: all 600ms ease-in-out;
  }
`;

export default Wrapper;
