const data = [
  {
    id: 0,
    bgImg: '/case-studies/decathlon.png',
    brand: 'Decathlon',
    title: 'Decathlon custom CMS & Corporate Website',
    description:
      'One of the most critical requirements for the project was to create a custom CMS for the website. To fulfill this requirement and meet all other project goals, we utilized React in the frontend, a serverless architecture with cloud functions in the backend, and Terraform for Infrastructure as Code (IaaC). Our team was thrilled to take on the challenge of working with a well-established brand to deliver a top-quality product.',
    keyFacts: [
      'CMS built from scratch',
      'Serverless architecture with cloud functions in the backend',
      'Usage of CircleCI and Terraform, both for CI/CD pipelines and IaaC'
    ],
    stack: ['aws', 'react', 'redux', 'terraform', 'circleci'],
    services: ['Software Development', 'Product Strategy & Consulting'],
    link: 'https://google.com'
  },
  {
    id: 1,
    bgImg: '/case-studies/thecoded.png',
    brand: 'TheCoded Technologies',
    title: 'Stop sending keynotes!',
    description:
      'This was our motto when starting the development of this website: it had to replace our keynote presentations! Our website is also the place where we implment our best pratices and where we can "afford" to take risks we want to test all our crazy and innovative ideas. Believe us, it has much more to discover than it looks at first glance.',
    keyFacts: [
      'Headless CMS - Contentful',
      'Used Atomic Design concepts also during development'
    ],
    stack: [
      'aws',
      'react',
      'redux',
      'contentful',
      'terraform',
      'circleci',
      'figma'
    ],
    services: ['Software Development', 'UX/UI Product Design'],
    link: 'https://google.com'
  },
  {
    id: 2,
    bgImg: '/case-studies/unii.png',
    brand: 'Unii Organic',
    title: 'Bio Sustainable Cosmetic E-commerce performance and sales boosting',
    description:
      'Unii Organic is an online store selling organic skincare products built on WordPress with a WooCommerce plugin. The project aimed to increase sales through website optimization and effective marketing strategies. The website used various plugins and tools to optimize the user experience, including Yoast SEO, Mailchimp, and Google Analytics. The website was optimized for mobile devices and implemented marketing strategies such as email marketing, social media marketing, and retargeting ads. As a result, the project successfully increased revenue and conversion rates and positioned Unii Organic as an eco-friendly and ethical skincare brand.',
    keyFacts: [
      'E-commerce Growth Strategy added to Product Consulting',
      'Renewed UX/UI approach & online store revamp',
      'First 3 months: conversion rate +20%, revenue and orders +55%'
    ],
    stack: ['wordpress', 'figma'],
    services: [
      'Software Development',
      'UX/UI Product Design',
      'Product Strategy & Consulting'
    ],
    link: 'https://google.com'
  },
  {
    id: 3,
    bgImg: '/case-studies/bab.png',
    brand: 'Barbearia à Benfica',
    title:
      'A unique concept worldwide where SLB fans can take care of hair, beards, mustaches & tattoos.',
    description:
      "A straightforward UX/UI discovery phase, followed by the development of a custom Shopify. The custom theme was built using Liquid, Shopify's templating language. The project resulted was an effective platform to showcase products and attract customers.",
    keyFacts: ['Shopify Theme - Custom Design & Development'],
    stack: ['shopify', 'figma'],
    services: ['Software Development', 'UX/UI Product Design'],
    link: 'https://google.com'
  }
];

export default data;
