import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';
import { theme } from '../../Subatoms/Theme';
import Typography from '../../Subatoms/Typography';
import Flex from '../../Atoms/Flex';
import Card from '../../Molecules/Card';

const CardsAccordion = (props) => {
  const {
    cards,
    cardWidthOpen,
    cardWidthClosed,
    cardHeightOpen,
    cardHeightClosed,
    cardPadding
  } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const handleMouseEnter = (idx) => {
    setActiveIndex(idx);
  };

  return (
    <Wrapper {...props}>
      {cards.map((card) => {
        return (
          <Card
            key={card.id}
            width={activeIndex === card.id ? cardWidthOpen : cardWidthClosed}
            height={activeIndex === card.id ? cardHeightOpen : cardHeightClosed}
            borderRadius="20px"
            margin={theme.spacing * 6}
            bgImg={`linear-gradient(135deg, rgba(27, 32, 38, 0) 0%, rgba(0, 0, 0, .8) 50%), url(${card.img})`}
            bgOpacity={activeIndex === card.id ? 0 : 1}
            bgColor={theme.colors.black.default}
            onMouseEnter={() => handleMouseEnter(card.id)}
            classes={activeIndex === card.id ? ['card-active'] : []}
            cardTransition="all 500ms ease-in-out"
          >
            {/* ! ACTIVE STATE */}
            <Flex
              width={{ default: '100%' }}
              maxWidth={
                activeIndex === card.id ? { default: '0' } : cardWidthOpen
              }
              height={{ default: '100%' }}
              alignItems={{ default: 'center' }}
              justifyContent={{ default: 'flex-start' }}
              className="text-rotation"
            >
              <Flex padding={cardPadding} width={{ default: 'unset' }}>
                <Typography
                  type="h2"
                  color={theme.colors.white.default}
                  text={card.title}
                />
              </Flex>
            </Flex>
            {/* ACTIVE STATE */}
            <Flex
              width={{ default: '100%' }}
              height={{ default: 'unset' }}
              className="active-wrapper"
              flexDirection={{ default: 'column' }}
              justifyContent={{ default: 'flex-end' }}
              padding={activeIndex === card.id ? cardPadding : { default: '0' }}
            >
              <Typography
                type="h2"
                color={theme.colors.white.default}
                text={card.title}
              />
              <div style={{ padding: '1rem 0' }} />
              <Typography
                type="p"
                color={theme.colors.white.default}
                text={card.description}
              />
            </Flex>
          </Card>
        );
      })}
    </Wrapper>
  );
};

CardsAccordion.defaultProps = {};

CardsAccordion.propTypes = {
  cards: PropTypes.array,
  cardWidthOpen: PropTypes.object,
  cardWidthClosed: PropTypes.object,
  cardHeightOpen: PropTypes.object,
  cardHeightClosed: PropTypes.object,
  cardPadding: PropTypes.object
};

export default CardsAccordion;
