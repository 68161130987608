import styled from 'styled-components';

import { theme } from '../../Subatoms/Theme';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  ${({ flexDirection }) =>
    flexDirection &&
    `flex-direction: ${
      flexDirection.xs ? flexDirection.xs : flexDirection.default
    };`}
  ${({ justifyContent }) =>
    justifyContent &&
    `justify-content: ${
      justifyContent.xs ? justifyContent.xs : justifyContent.default
    };`}
${({ alignItems }) =>
    alignItems &&
    `align-items: ${alignItems.xs ? alignItems.xs : alignItems.default};`}
${({ wrap }) => wrap && `flex-wrap: ${wrap.xs ? wrap.xs : wrap.default};`}
${({ gap }) => gap && `gap: ${gap.xs ? gap.xs : gap.default};`}

& .content-wrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
  }

  & .content-wrapper .active-wrapper {
    position: relative;
    width: 100%;
    max-width: 0;
    opacity: 0;
    right: -450px;
  }

  & .card-active .content-wrapper .active-wrapper {
    right: 0px;
    max-width: 450px;
    opacity: 1;
    transition: opacity 1000ms ease-in-out 500ms;
  }

  & .text-rotation {
    transform: unset;
    writing-mode: unset;
    align-self: flex-end;
    overflow: hidden;
  }

  @media ${theme.responsiveCSSRules.sm} {
    ${({ flexDirection }) =>
      flexDirection &&
      flexDirection.sm &&
      `flex-direction: ${flexDirection.sm};`}
    ${({ justifyContent }) =>
      justifyContent &&
      justifyContent.sm &&
      `justify-content: ${justifyContent.sm};`}
  ${({ alignItems }) =>
      alignItems && alignItems.sm && `align-items: ${alignItems.sm};`}
  ${({ wrap }) => wrap && wrap.sm && `flex-wrap: ${wrap.sm};`}
  ${({ gap }) => gap && gap.sm && `gap: ${gap.sm};`}

    & .text-rotation {
      transform: rotate(180deg);
      writing-mode: vertical-rl;
    }
  }

  @media ${theme.responsiveCSSRules.md} {
    ${({ flexDirection }) =>
      flexDirection &&
      flexDirection.md &&
      `flex-direction: ${flexDirection.md};`}
    ${({ justifyContent }) =>
      justifyContent &&
      justifyContent.md &&
      `justify-content: ${justifyContent.md};`}
  ${({ alignItems }) =>
      alignItems && alignItems.md && `align-items: ${alignItems.md};`}
  ${({ wrap }) => wrap && wrap.md && `flex-wrap: ${wrap.md};`}
  ${({ gap }) => gap && gap.md && `gap: ${gap.md};`}
  }

  @media ${theme.responsiveCSSRules.lg} {
    ${({ flexDirection }) =>
      flexDirection &&
      flexDirection.lg &&
      `flex-direction: ${flexDirection.lg};`}
    ${({ justifyContent }) =>
      justifyContent &&
      justifyContent.lg &&
      `justify-content: ${justifyContent.lg};`}
  ${({ alignItems }) =>
      alignItems && alignItems.lg && `align-items: ${alignItems.lg};`}
  ${({ wrap }) => wrap && wrap.lg && `flex-wrap: ${wrap.lg};`}
  ${({ gap }) => gap && gap.lg && `gap: ${gap.lg};`}
  }

  @media ${theme.responsiveCSSRules.xl} {
    ${({ flexDirection }) =>
      flexDirection &&
      flexDirection.xl &&
      `flex-direction: ${flexDirection.xl};`}
    ${({ justifyContent }) =>
      justifyContent &&
      justifyContent.xl &&
      `justify-content: ${justifyContent.xl};`}
  ${({ alignItems }) =>
      alignItems && alignItems.xl && `align-items: ${alignItems.xl};`}
  ${({ wrap }) => wrap && wrap.xl && `flex-wrap: ${wrap.xl};`}
  ${({ gap }) => gap && gap.xl && `gap: ${gap.xl};`}
  }
`;

export default Wrapper;
