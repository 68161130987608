import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html {
    font-size: 12px;
  }

  html, body {
    height: 100%;
    width: 100%;
  }

  body {
    margin: 0;
    padding: 0;
  }

  html, body, h1, h2, h3, h4, h5, h6, p, span, a, button, ul, ol, li {
    font-family: 'Poppins', sans-serif;
  }
`;

export default GlobalStyles;
