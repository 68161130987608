import React from 'react';

import { theme } from '../../Subatoms/Theme';
import Wrapper from './Wrapper';

// import Typography from '../../Subatoms/Typography';
import Flex from '../../Atoms/Flex';
import Button from '../../Molecules/Button';

const Navbar = (props) => {
  return (
    <Wrapper {...props}>
      <Flex maxWidth={{ default: '1440px' }} margin={{ default: '0 auto' }}>
        <Flex
          justifyContent={{ default: 'space-between' }}
          alignItems={{ default: 'center' }}
          margin={{
            default: `${theme.spacing * 2}px`,
            sm: `${theme.spacing * 3}px ${theme.spacing * 4}px`,
            md: `${theme.spacing * 3}px ${theme.spacing * 8}px`
          }}
        >
          <img src="./logo/tc_white.png" alt="TheCoded Logo" width="120" />
          <Button
            text="Get in touch"
            padding={`${theme.spacing * 1.5}px ${theme.spacing * 3}px`}
            onClick={() => {
              window.open(
                'https://meetings.hubspot.com/jmm1',
                '_blank',
                'noopener,noreferrer'
              );
            }}
          />
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default Navbar;
