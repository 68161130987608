import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';

const Slider = (props) => {
  const { data, reviews, height, columns } = props;

  return (
    <Swiper
      pagination={{
        clickable: true,
        dynamicBullets: true
      }}
      modules={[Pagination]}
      spaceBetween={20}
      breakpoints={{
        300: {
          slidesPerView: 1
        },
        700: {
          slidesPerView: 2
        },
        1000: {
          slidesPerView: columns ? columns : 3
        }
      }}
    >
      <SwiperSlide>
        <Paper
          sx={{
            backgroundColor: '#5b93fc',
            color: '#fff',
            height: '100%'
          }}
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Stack
              spacing={1}
              sx={{
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Stack
                direction="row"
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '1rem'
                }}
              >
                <Typography variant="h3">{data.rating}</Typography>
                <Box>
                  <StarIcon fontSize="large" />
                </Box>
              </Stack>
              <Box>
                <Typography variant="h6" sx={{ textAlign: 'center' }}>
                  {data.name}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" sx={{ textAlign: 'center' }}>
                  {data.user_ratings_total}&nbsp;reviews
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Paper>
      </SwiperSlide>
      {reviews.map((review, i) => (
        <SwiperSlide key={i}>
          <Paper
            key={i}
            sx={{
              height: '100%',
              overflow: 'scroll',
              maxHeight: `${height}px`
            }}
          >
            <Box
              sx={{
                padding: '2rem'
              }}
            >
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  sx={{ alignItems: 'center', gap: '1rem' }}
                >
                  <Box>
                    <Avatar
                      alt={review.author_name}
                      src={review.profile_photo_url}
                    />
                  </Box>
                  <Box>{review.author_name}</Box>
                </Stack>
                <Box>
                  <Rating name="read-only" value={review.rating} readOnly />
                </Box>
                <Box>
                  <Typography variant="body1" gutterBottom>
                    {review.text}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" display="block">
                    {review.relative_time_description}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Paper>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

Slider.defaultProps = {};

Slider.propTypes = {
  data: PropTypes.object,
  reviews: PropTypes.array,
  height: PropTypes.string,
  columns: PropTypes.number
};

export default Slider;
