import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 2rem auto;
  max-width: 1000px;
  padding: 0 2%;
  height: 393px;

  & .swiper-horizontal {
    padding: 0 1%;
  }

  & .swiper-wrapper {
    padding: 2rem 0 6rem;
  }

  & .swiper-slide {
    height: auto;
  }
`;

export default Wrapper;
