import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { theme } from '../../Subatoms/Theme';
import Wrapper from './Wrapper';

import Flex from '../../Atoms/Flex';
import Typography from '../../Subatoms/Typography';
import Button from '../../Molecules/Button';

const GradientCTA = (props) => {
  const { mainContent, subcontent, typingSpeed, selectedTime, showTime } =
    props;

  const [activeIndex, setActiveIndex] = useState(0);
  const [displayContent, setDisplayContent] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  // Init
  useEffect(() => {
    setDisplayContent(mainContent[activeIndex]);
  }, []);

  // Controls Text Writing
  useEffect(() => {
    if (!isSelected && !isDeleted) {
      setTimeout(() => {
        setIsSelected(true);
      }, selectedTime);
    }

    if (!isSelected && isDeleted) {
      if (displayContent === mainContent[activeIndex]) {
        setTimeout(() => {
          setIsSelected(true);
          setIsDeleted(false);
        }, selectedTime);
      } else {
        let newString = mainContent[activeIndex].substring(
          0,
          displayContent.length + 1
        );
        setTimeout(() => {
          setDisplayContent(newString);
        }, typingSpeed);
      }
    }
  }, [displayContent]);

  // Controls Deleting Text
  useEffect(() => {
    if (isSelected && !isDeleted) {
      setTimeout(() => {
        setDisplayContent('');
        setIsSelected(false);
        setIsDeleted(true);
        setActiveIndex(
          activeIndex + 1 < mainContent.length ? activeIndex + 1 : 0
        );
      }, showTime);
    }
  }, [isSelected]);

  return (
    <Wrapper {...props}>
      <Flex
        justifyContent={{ default: 'center' }}
        height={{ default: '3rem', sm: '7.6rem', md: '8.4rem', lg: '10rem' }}
      >
        <div
          className="dynamic-content"
          style={{ position: 'relative', zIndex: '0' }}
        >
          <span
            className={isSelected ? 'is-selected' : ''}
            style={{
              position: 'absolute',
              width: isSelected ? '100%' : '0',
              height: '100%',
              background: `${theme.colors.black.default}`,
              zIndex: '-1',
              left: '0',
              top: '0'
            }}
          ></span>
          <Typography
            type="display"
            text={displayContent}
            align={{ default: 'center' }}
            color={theme.colors.white.default}
          />
        </div>
      </Flex>
      <Flex
        justifyContent={{ default: 'center' }}
        alignItems={{ default: 'center' }}
        height={{ default: '10rem' }}
      >
        <div
          style={{
            overflow: 'hidden',
            height:
              displayContent === mainContent[activeIndex] && !isSelected
                ? '100%'
                : '0',
            transition: 'all 500ms ease-in-out'
          }}
        >
          <Typography
            type="display"
            text={subcontent[activeIndex]}
            align={{ default: 'center' }}
            color={theme.colors.white.default}
          />
        </div>
      </Flex>
      <div style={{ margin: '4rem 0 0' }}>
        <Button
          secondary
          text="Book a free Discovery Call"
          onClick={() => {
            window.open(
              'https://meetings.hubspot.com/jmm1',
              '_blank',
              'noopener,noreferrer'
            );
          }}
        />
      </div>
    </Wrapper>
  );
};

GradientCTA.defaultProps = {
  mainContent: [
    'Have an idea?',
    'Have a project?',
    'Have a challenge?',
    "You've got this far."
  ],
  subcontent: [
    "Let's make it happen.",
    "Let's build it right.",
    "Let's solve it together.",
    'Ready to get started?'
  ],
  typingSpeed: 100 - Math.random() * 100,
  selectedTime: 3000,
  showTime: 1500,
  height: {
    default: '440px',
    sm: '500px',
    md: '450px',
    lg: '530px'
  }
};

GradientCTA.propTypes = {
  mainContent: PropTypes.array,
  subcontent: PropTypes.array,
  typingSpeed: PropTypes.number,
  selectedTime: PropTypes.number,
  showTime: PropTypes.number,
  height: PropTypes.object
};

export default GradientCTA;
