import styled from 'styled-components';

import { theme } from '../../Subatoms/Theme';

const Wrapper = styled.button`
  background: ${({ secondary }) =>
    !secondary
      ? `linear-gradient(
      90deg,
      ${theme.colors.secondary.default} 0%,
      ${theme.colors.primary.default} 100%
    );`
      : theme.colors.white.default};
  color: ${({ secondary }) =>
    !secondary ? theme.colors.white.default : theme.colors.black.default};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding};
  border-radius: 50px;
  background-position: 0;
  background-size: 100%;
  transition: all 500ms ease-in-out;

  &:hover {
    ${({ secondary }) =>
      secondary && `background: ${theme.colors.black.default}`};
    ${({ secondary }) => !secondary && `background-position: 50%`};
    ${({ secondary }) => !secondary && `background-size: 300%;`};
    ${({ secondary }) => !secondary && `border-radius: 10px;`};
    color: ${({ secondary }) =>
      !secondary ? theme.colors.white.default : theme.colors.white.default};
    transition: all 500ms ease-in-out;
  }
`;

export default Wrapper;
