import styled from 'styled-components';

import { theme } from '../../Subatoms/Theme';

const Wrapper = styled.div`
  display: flex;

  ${({ flexDirection }) =>
    flexDirection &&
    `flex-direction: ${
      flexDirection.xs ? flexDirection.xs : flexDirection.default
    };`}
  ${({ justifyContent }) =>
    justifyContent &&
    `justify-content: ${
      justifyContent.xs ? justifyContent.xs : justifyContent.default
    };`}
  ${({ alignItems }) =>
    alignItems &&
    `align-items: ${alignItems.xs ? alignItems.xs : alignItems.default};`}
  ${({ wrap }) => wrap && `flex-wrap: ${wrap.xs ? wrap.xs : wrap.default};`}
  ${({ gap }) => gap && `gap: ${gap.xs ? gap.xs : gap.default};`}

  ${({ width }) => width && `width: ${width.xs ? width.xs : width.default};`}
  ${({ maxWidth }) =>
    maxWidth && `max-width: ${maxWidth.xs ? maxWidth.xs : maxWidth.default};`}
  ${({ height }) =>
    height && `height: ${height.xs ? height.xs : height.default};`}

  ${({ margin }) =>
    margin && `margin: ${margin.xs ? margin.xs : margin.default};`}
  ${({ padding }) =>
    padding && `padding: ${padding.xs ? padding.xs : padding.default};`}

  @media ${theme.responsiveCSSRules.sm} {
    ${({ flexDirection }) =>
      flexDirection &&
      flexDirection.sm &&
      `flex-direction: ${flexDirection.sm};`}
    ${({ justifyContent }) =>
      justifyContent &&
      justifyContent.sm &&
      `justify-content: ${justifyContent.sm};`}
    ${({ alignItems }) =>
      alignItems && alignItems.sm && `align-items: ${alignItems.sm};`}
    ${({ wrap }) => wrap && wrap.sm && `flex-wrap: ${wrap.sm};`}
    ${({ gap }) => gap && gap.sm && `gap: ${gap.sm};`}

    ${({ width }) => width && width.sm && `width: ${width.sm};`}
    ${({ maxWidth }) => maxWidth && maxWidth.sm && `max-width: ${maxWidth.sm};`}
    ${({ height }) => height && height.sm && `height: ${height.sm};`}

    ${({ margin }) => margin && margin.sm && `margin: ${margin.sm};`}
    ${({ padding }) => padding && padding.sm && `padding: ${padding.sm};`}
  }

  @media ${theme.responsiveCSSRules.md} {
    ${({ flexDirection }) =>
      flexDirection &&
      flexDirection.md &&
      `flex-direction: ${flexDirection.md};`}
    ${({ justifyContent }) =>
      justifyContent &&
      justifyContent.md &&
      `justify-content: ${justifyContent.md};`}
    ${({ alignItems }) =>
      alignItems && alignItems.md && `align-items: ${alignItems.md};`}
    ${({ wrap }) => wrap && wrap.md && `flex-wrap: ${wrap.md};`}
    ${({ gap }) => gap && gap.md && `gap: ${gap.md};`}

    ${({ width }) => width && width.md && `width: ${width.md};`}
    ${({ maxWidth }) => maxWidth && maxWidth.md && `max-width: ${maxWidth.md};`}
    ${({ height }) => height && height.md && `height: ${height.md};`}

    ${({ margin }) => margin && margin.md && `margin: ${margin.md};`}
    ${({ padding }) => padding && padding.md && `padding: ${padding.md};`}
  }

  @media ${theme.responsiveCSSRules.lg} {
    ${({ flexDirection }) =>
      flexDirection &&
      flexDirection.lg &&
      `flex-direction: ${flexDirection.lg};`}
    ${({ justifyContent }) =>
      justifyContent &&
      justifyContent.lg &&
      `justify-content: ${justifyContent.lg};`}
    ${({ alignItems }) =>
      alignItems && alignItems.lg && `align-items: ${alignItems.lg};`}
    ${({ wrap }) => wrap && wrap.lg && `flex-wrap: ${wrap.lg};`}
    ${({ gap }) => gap && gap.lg && `gap: ${gap.lg};`}

    ${({ width }) => width && width.lg && `width: ${width.lg};`}
    ${({ maxWidth }) => maxWidth && maxWidth.lg && `max-width: ${maxWidth.lg};`}
    ${({ height }) => height && height.lg && `height: ${height.lg};`}

    ${({ margin }) => margin && margin.lg && `margin: ${margin.lg};`}
    ${({ padding }) => padding && padding.lg && `padding: ${padding.lg};`}
  }

  @media ${theme.responsiveCSSRules.xl} {
    ${({ flexDirection }) =>
      flexDirection &&
      flexDirection.xl &&
      `flex-direction: ${flexDirection.xl};`}
    ${({ justifyContent }) =>
      justifyContent &&
      justifyContent.xl &&
      `justify-content: ${justifyContent.xl};`}
    ${({ alignItems }) =>
      alignItems && alignItems.xl && `align-items: ${alignItems.xl};`}
    ${({ wrap }) => wrap && wrap.xl && `flex-wrap: ${wrap.xl};`}
    ${({ gap }) => gap && gap.xl && `gap: ${gap.xl};`}

    ${({ width }) => width && width.xl && `width: ${width.xl};`}
    ${({ maxWidth }) => maxWidth && maxWidth.xl && `max-width: ${maxWidth.xl};`}
    ${({ height }) => height && height.xl && `height: ${height.xl};`}

    ${({ margin }) => margin && margin.xl && `margin: ${margin.xl};`}
    ${({ padding }) => padding && padding.xl && `padding: ${padding.xl};`}
  }
`;

export default Wrapper;
