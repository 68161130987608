import React from 'react';
import { Helmet } from 'react-helmet';

import Wrapper from './Wrapper';
import { theme } from '../../Subatoms/Theme';
import Typography from '../../Subatoms/Typography';
import caseStudies from '../../Subatoms/Data/caseStudies';
import Flex from '../../Atoms/Flex';
import Logo from '../../Atoms/Logo';
import Hero from '../../Organisms/Hero';
import Slider from '../../Organisms/Slider';
import CardsAccordion from '../../Organisms/CardsAccordion';

import services from '../../Subatoms/Data/services';
import clients from '../../Subatoms/Data/clients';

const Home = (props) => {
  return (
    <>
      <Helmet>
        {/* PRELOAD IMAGES */}
        <link rel="preload" as="image" href="/logo/tc_black.png" />
        <link rel="preload" as="image" href="/logo/tc_white.png" />
        <link rel="preload" as="image" href="/case-studies/decathlon.png" />
        <link rel="preload" as="image" href="/case-studies/thecoded.png" />
        <link rel="preload" as="image" href="/case-studies/unii.png" />
        <link rel="preload" as="image" href="/case-studies/bab.png" />
        <link rel="preload" as="image" href="/services/dev.jpg" />
        <link rel="preload" as="image" href="/services/popm.jpg" />
        <link rel="preload" as="image" href="/services/uxui.jpg" />
        {/* FACEBOOK OPEN GRAPH */}
        <meta property="og:url" content="https://thecoded.tech/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="TheCoded Technologies - IT Consulting"
        />
        <meta
          property="og:description"
          content="IT &amp; Product consulting, UX/UI product design, software engineering, web development, mobile development services."
        />
        <meta property="og:image" content="https://thecoded.tech/logo512.png" />
        <meta property="og:image" content="https://thecoded.tech/logo192.png" />
        {/* TWITTER OPEN GRAPH */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="thecoded.tech" />
        <meta property="twitter:url" content="https://thecoded.tech/" />
        <meta
          name="twitter:title"
          content="TheCoded Technologies - IT Consulting"
        />
        <meta
          name="twitter:description"
          content="IT &amp; Product consulting, UX/UI product design, software engineering, web development, mobile development services."
        />
        <meta
          name="twitter:image"
          content="https://thecoded.tech//logo_192.png"
        />
      </Helmet>
      <Wrapper {...props}>
        {/* HERO */}
        <Hero
          height={{
            default: '650px',
            md: '750px',
            lg: '900px'
          }}
        />
        {/* ABOUT US */}
        <Flex maxWidth={{ default: '1440px' }} margin={{ default: '0 auto' }}>
          <Flex
            flexDirection={{ default: 'column' }}
            padding={{
              default: `${theme.spacing * 28}px ${theme.spacing * 2}px`,
              sm: `${theme.spacing * 28}px ${theme.spacing * 4}px`,
              lg: `${theme.spacing * 45}px ${theme.spacing * 22}px`
            }}
          >
            <Flex
              margin={{
                default: `${theme.spacing * 2}px ${theme.spacing * 0}px`
              }}
            >
              <Typography type="caption" text="WHAT WE DO" />
            </Flex>
            <Typography
              type="h2"
              text="We are experts in creating, building and growing digital products."
            />
            <Typography
              type="h2"
              text="Our extensive experience and technical skills, gathered across multiple companies, clients and industries, will be game-changing assets for your company or project."
            />
          </Flex>
        </Flex>
        {/* CASE STUDIES */}
        <Flex maxWidth={{ default: '1440px' }} margin={{ default: '0 auto' }}>
          <Flex flexDirection={{ default: 'column' }}>
            <Flex
              flexDirection={{ default: 'column' }}
              padding={{
                default: `${theme.spacing * 0}px ${theme.spacing * 2}px`,
                sm: `${theme.spacing * 0}px ${theme.spacing * 4}px`,
                lg: `${theme.spacing * 0}px ${theme.spacing * 22}px`
              }}
              width={{ default: 'unset' }}
            >
              <Typography type="caption" text="CASE STUDIES" />
              <Typography type="h2" text="The impact we are creating" />
            </Flex>
            <Flex
              padding={{
                default: `${theme.spacing * 2}px`,
                sm: `${theme.spacing * 4}px`,
                lg: `${theme.spacing * 8}px`
              }}
              width={{ default: 'unset' }}
            >
              <Slider slides={caseStudies} />
            </Flex>
          </Flex>
        </Flex>
        {/* SERVICES */}
        <Flex maxWidth={{ default: '1440px' }} margin={{ default: '0 auto' }}>
          <Flex
            width={{ default: 'unset' }}
            padding={{
              default: `${theme.spacing * 20}px ${theme.spacing * 2}px`,
              sm: `${theme.spacing * 20}px ${theme.spacing * 4}px`,
              lg: `${theme.spacing * 30}px ${theme.spacing * 8}px`
            }}
            flexDirection={{ default: 'column', md: 'row' }}
            justifyContent={{ default: 'space-between' }}
            alignItems={{ default: 'center' }}
          >
            <Flex
              flexDirection={{ default: 'column' }}
              width={{ default: '100%', lg: '25%' }}
              padding={{
                default: `${theme.spacing * 8}px ${theme.spacing * 0}px`,
                lg: `${theme.spacing * 0}px ${theme.spacing * 0}px`
              }}
            >
              <Typography type="caption" text="SERVICES" />
              <Typography type="h2" text="Making your ideas happen" />
            </Flex>
            <Flex width={{ default: '100%', lg: '70%' }}>
              <CardsAccordion
                cards={services}
                flexDirection={{ default: 'column', sm: 'row' }}
                justifyContent={{ default: 'space-between', lg: 'flex-end' }}
                gap={{
                  default: `${theme.spacing * 2}px`,
                  sm: `${theme.spacing * 2}px`,
                  md: `${theme.spacing * 4}px`
                }}
                cardWidthOpen={{
                  default: '100%',
                  sm: '344px',
                  md: '310px',
                  lg: '422px'
                }}
                cardWidthClosed={{
                  default: '100%',
                  sm: '164px',
                  md: '146px',
                  lg: '200px'
                }}
                cardHeightOpen={{ default: '398px', sm: '410px', lg: '500px' }}
                cardHeightClosed={{
                  default: '140px',
                  sm: '410px',
                  lg: '500px'
                }}
                cardPadding={{ default: '32px' }}
              />
            </Flex>
          </Flex>
        </Flex>
        {/* EXPERIENCE */}
        <Flex maxWidth={{ default: '1440px' }} margin={{ default: '0 auto' }}>
          <Flex flexDirection={{ default: 'column' }}>
            <Flex
              width={{ default: '100%' }}
              justifyContent={{ default: 'center' }}
            >
              <Flex
                flexDirection={{ default: 'column' }}
                width={{ default: '95%', md: '40%' }}
                alignItems={{ default: 'center' }}
                justifyContent={{ default: 'center' }}
              >
                <Typography
                  type="caption"
                  text="EXPERIENCE"
                  align={{ default: 'center' }}
                />
                <Typography
                  type="h2"
                  text="We partner with forward-thinking companies"
                  align={{ default: 'center' }}
                />
              </Flex>
            </Flex>
            <Flex
              padding={{
                default: `${theme.spacing * 8}px ${theme.spacing * 4}px ${
                  theme.spacing * 20
                }px`,
                sm: `${theme.spacing * 8}px ${theme.spacing * 4}px ${
                  theme.spacing * 20
                }px`,
                lg: `${theme.spacing * 8}px ${theme.spacing * 8}px ${
                  theme.spacing * 30
                }px`
              }}
              width={{ default: 'unset' }}
              justifyContent={{ default: 'center' }}
            >
              <Flex
                width={{ default: '90%', md: '80%' }}
                justifyContent={{ default: 'flex-start', sm: 'space-between' }}
                wrap={{ default: 'wrap' }}
                gap={{
                  default: `${theme.spacing * 6}px ${theme.spacing * 3}px`
                }}
              >
                {clients.map((client) => {
                  return (
                    <Flex
                      key={client.id}
                      width={{ default: '25%', sm: '18%', md: '20%' }}
                      justifyContent={{ default: 'center' }}
                    >
                      <Logo
                        type={client.logo}
                        link={client.link}
                        width="100%"
                        height="100%"
                        maxWidth="150px"
                        maxHeight="80px"
                      />
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Wrapper>
    </>
  );
};

export default Home;
