import styled from 'styled-components';

import { theme } from '../../Subatoms/Theme';

const Wrapper = styled.div`
  ${({ width }) => width && `width: ${width.xs ? width.xs : width.default};`}
  ${({ height }) =>
    height && `height: ${height.xs ? height.xs : height.default};`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
  background: ${({ bgColor }) => (bgColor ? bgColor : 'white')};
  overflow: hidden;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 8px 16px rgb(0 0 0 / 25%);
  ${({ cardTransition }) => cardTransition && `transition: ${cardTransition};`}
  position: relative;
  z-index: 0;
  border-radius: ${({ borderRadius }) => borderRadius};

  &.active {
    transition: all 500ms ease-in-out;
  }

  & .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${({ bgImg }) => `
      background: ${bgImg};
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;`}
    ${({ bgOpacity }) => `
      opacity: ${bgOpacity};
    transition: all 600ms ease-in-out;
`}
  }

  & .content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  @media ${theme.responsiveCSSRules.sm} {
    ${({ width }) => width && width.sm && `width: ${width.sm};`}
    ${({ height }) => height && height.sm && `height: ${height.sm};`}
  }

  @media ${theme.responsiveCSSRules.md} {
    ${({ width }) => width && width.md && `width: ${width.md};`}
    ${({ height }) => height && height.md && `height: ${height.md};`}
  }

  @media ${theme.responsiveCSSRules.lg} {
    ${({ width }) => width && width.lg && `width: ${width.lg};`}
    ${({ height }) => height && height.lg && `height: ${height.lg};`}
  }

  @media ${theme.responsiveCSSRules.xl} {
    ${({ width }) => width && width.xl && `width: ${width.xl};`}
    ${({ height }) => height && height.xl && `height: ${height.xl};`}
  }
`;

export default Wrapper;
