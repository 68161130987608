import React from 'react';

import { theme } from '../../Subatoms/Theme';
import Wrapper from './Wrapper';

import Typography from '../../Subatoms/Typography';
import Flex from '../../Atoms/Flex';
import Icon from '../../Atoms/Icon';
import GradientCTA from '../../Organisms/GradientCTA';

const Footer = (props) => {
  return (
    <Wrapper {...props}>
      <Flex maxWidth={{ default: '1440px' }} margin={{ default: '0 auto' }}>
        <Flex
          flexDirection={{ default: 'column' }}
          justifyContent={{ default: 'center' }}
          alignItems={{ default: 'center' }}
          padding={{
            default: `${theme.spacing * 2}px`,
            sm: `${theme.spacing * 4}px`,
            lg: `${theme.spacing * 8}px`
          }}
        >
          <GradientCTA />
          <Flex
            flexDirection={{ default: 'column', sm: 'row' }}
            margin={{
              default: `${theme.spacing * 10}px 0 0`,
              lg: `${theme.spacing * 17}px 0 0`
            }}
            alignItems={{ default: 'flex-end' }}
          >
            <Flex
              flexDirection={{ default: 'column' }}
              alignItems={{ default: 'center', sm: 'flex-start' }}
            >
              <img src="./logo/tc_white.png" alt="TheCoded Logo" width="170" />
              <Flex
                flexDirection={{ default: 'column' }}
                alignItems={{ default: 'center', sm: 'flex-start' }}
                margin={{ default: `${theme.spacing * 3}px 0 0` }}
              >
                <Typography
                  type="p"
                  text="Build your product better."
                  color={theme.colors.white.default}
                  fontSize={{ default: '1.6rem' }}
                />
                <Typography
                  type="p"
                  text="Monetize it faster."
                  color={theme.colors.white.default}
                  fontSize={{ default: '1.6rem' }}
                />
              </Flex>
            </Flex>
            <Flex
              flexDirection={{ default: 'column' }}
              alignItems={{ default: 'center', sm: 'flex-end' }}
              margin={{ default: `${theme.spacing * 5}px 0 0`, sm: 'unset' }}
            >
              <Flex
                justifyContent={{ default: 'center', sm: 'flex-end' }}
                gap={{ default: '20px' }}
                margin={{ default: `0 0 ${theme.spacing * 2}px` }}
              >
                <Icon
                  type="instagram"
                  link="https://www.instagram.com/thecoded_tech"
                  stroke={theme.colors.white.default}
                  hoveredStroke={theme.colors.primary.default}
                  hoveredStrokeWidth="2"
                />
                <Icon
                  type="linkedin"
                  link="https://www.linkedin.com/company/thecoded-tech"
                  stroke={theme.colors.white.default}
                  hoveredStroke={theme.colors.primary.default}
                  hoveredStrokeWidth="2"
                />
              </Flex>
              <Typography
                type="p"
                text={`©${new Date().getFullYear()} TheCoded Technologies | All rights reserved`}
                color={theme.colors.white.default}
                fontSize={{ default: '1.2rem' }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default Footer;
