/* eslint-disable */

import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import axios from 'axios';

const gtmArgs = {
  gtmId: 'GTM-MGB29QW'
};

import Navbar from './Organisms/Navbar';
import Footer from './Organisms/Footer';

import Home from './Pages/Home';

import { GoogleReviews } from './Pages/Widgets';

const Auth = () => {
  const handleAuth = async () => {
    const linkedinURL = 'https://www.linkedin.com/oauth/v2/authorization';
    try {
      const res = await axios({
        method: 'GET',
        url: linkedinURL,
        params: {
          client_id: '77bm5giwzqvsdo',
          redirect_uri: 'http://127.0.0.1:3000/auth',
          state: 'DCEeFWf45A53sdfKef424',
          scope: 'r_liteprofile%20r_emailaddress%20w_member_social'
        }
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {/* Auth Page
      <button onClick={handleAuth}>Auth</button> */}
    </div>
  );
};

const App = () => {
  // Initialize Google Tag Manager only in prod
  if (window.location.hostname === process.env.REACT_APP_HOSTNAME) {
    TagManager.initialize(gtmArgs);
  }

  const MainRoutes = () => (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );

  return (
    <Routes>
      <Route element={<MainRoutes />}>
        <Route path="/" element={<Home />} />
      </Route>
      <Route
        path="/widgets/google-reviews/:placeId"
        element={<GoogleReviews />}
      />
      <Route path="auth" element={<Auth />} />
      {/* <Route path="*" element={<Error />} /> */}
    </Routes>
  );
};

export default App;
