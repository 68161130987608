import React from 'react';
import PropTypes from 'prop-types';

import { theme } from '../../Subatoms/Theme';
import Wrapper from './Wrapper';

const Button = (props) => {
  const { onClick, text } = props;
  return (
    <Wrapper {...props} onClick={onClick}>
      {text}
    </Wrapper>
  );
};

Button.defaultProps = {
  secondary: false,
  onClick: () => {},
  text: 'Click me',
  padding: `${theme.spacing * 2.5}px ${theme.spacing * 5}px`
};

Button.propTypes = {
  secondary: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  padding: PropTypes.string
};

export default Button;
