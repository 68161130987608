// import { useRef, useLayoutEffect } from 'react';

const isBrowser = typeof window !== `undefined`;

export default function getElementPosition(element) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return position;
}
