import styled from 'styled-components';

import { theme } from '../../Subatoms/Theme';

const Wrapper = styled.div`
  --swiper-pagination-progressbar-size: ${theme.spacing * 1}px;
  --swiper-pagination-color: linear-gradient(
    90deg,
    ${theme.colors.primary.default} 0%,
    ${theme.colors.secondary.default} 100%
  );
  --swiper-pagination-progressbar-bg-color: #f4f5f7;

  width: 100%;
  min-height: 400px;

  & .swiper {
    width: 100%;
    height: 100%;
    border-radius: 0 0 30px 30px;
  }

  & .swiper-pagination-progressbar {
    border-bottom: ${theme.spacing / 2}px solid ${theme.colors.white.default};
  }

  & .swiper-slide {
    border-radius: 0 0 30px 30px;
    overflow: hidden;
    position: relative;
  }

  & .autoplay-progress {
    position: absolute;
    left: 16px;
    bottom: 16px;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: ${theme.colors.white.default};
  }

  & .autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: ${theme.colors.white.default};
    fill: none;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
  }

  & .slider-card-title {
    overflow: hidden;
    height: 100%;
    max-height: 0;
    transition: all 1000ms ease-in-out;
  }

  & .slider-card-title.active {
    max-height: 200px;
    transition: all 1000ms ease-in-out 500ms;
  }

  & .slider-card-content {
    overflow: hidden;
    height: 100%;
    max-height: 0;
    margin: 0;
    transition: all 1000ms ease-in-out;
  }

  & .slider-card-content.active {
    max-height: 500px;
    margin: 2rem 0 0;
    transition: all 1000ms ease-in-out 500ms;
  }

  @media ${theme.responsiveCSSRules.sm} {
    min-height: 500px;
  }

  @media ${theme.responsiveCSSRules.md} {
    min-height: 600px;

    & .slider-card-title {
      max-height: 100%;
    }
  }

  @media ${theme.responsiveCSSRules.lg} {
    min-height: 800px;
  }
`;

export default Wrapper;
