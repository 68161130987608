import styled from 'styled-components';

import { theme } from '../Theme';

const Wrapper = styled.div`
  &.display {
    ${({ fontSize }) =>
      fontSize
        ? `font-size: ${fontSize.xs ? fontSize.xs : fontSize.default};`
        : 'font-size: 3rem;'}
    ${({ lineHeight }) =>
      lineHeight
        ? `line-height: ${lineHeight.xs ? lineHeight.xs : lineHeight.default};`
        : 'line-height: 3rem;'}
    font-weight: 500;
    margin: 0;
    ${({ align }) =>
      align && ` text-align: ${align.xs ? align.xs : align.default};`}
    color: ${({ color }) => color};
  }

  &.h1 {
    ${({ align }) =>
      align && ` text-align: ${align.xs ? align.xs : align.default};`}
    color: ${({ color }) => color};
  }

  &.h2 {
    ${({ fontSize }) =>
      fontSize
        ? `font-size: ${fontSize.xs ? fontSize.xs : fontSize.default};`
        : 'font-size: 2.4rem;'}
    ${({ lineHeight }) =>
      lineHeight
        ? `line-height: ${lineHeight.xs ? lineHeight.xs : lineHeight.default};`
        : 'line-height: 3.4rem;'}
    font-weight: 400;
    margin: 0;
    ${({ align }) =>
      align && ` text-align: ${align.xs ? align.xs : align.default};`}
    color: ${({ color }) => color};
  }

  &.h3 {
    ${({ fontSize }) =>
      fontSize
        ? `font-size: ${fontSize.xs ? fontSize.xs : fontSize.default};`
        : 'font-size: 2rem;'}
    ${({ lineHeight }) =>
      lineHeight
        ? `line-height: ${lineHeight.xs ? lineHeight.xs : lineHeight.default};`
        : 'line-height: 3rem;'}
  font-weight: 400;
    margin: 0;
    ${({ align }) =>
      align && ` text-align: ${align.xs ? align.xs : align.default};`}
    color: ${({ color }) => color};
  }

  &.p {
    ${({ fontSize }) =>
      fontSize
        ? `font-size: ${fontSize.xs ? fontSize.xs : fontSize.default};`
        : 'font-size: 1.2rem;'}
    ${({ lineHeight }) =>
      lineHeight
        ? `line-height: ${lineHeight.xs ? lineHeight.xs : lineHeight.default};`
        : 'line-height: 2rem;'}
    font-weight: 400;
    margin: 0;
    ${({ align }) =>
      align && ` text-align: ${align.xs ? align.xs : align.default};`}
    color: ${({ color }) => color};
  }

  &.caption {
    ${({ fontSize }) =>
      fontSize
        ? `font-size: ${fontSize.xs ? fontSize.xs : fontSize.default};`
        : 'font-size: 1rem;'}
    ${({ lineHeight }) =>
      lineHeight
        ? `line-height: ${lineHeight.xs ? lineHeight.xs : lineHeight.default};`
        : 'line-height: 2rem;'}
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
    ${({ align }) =>
      align && ` text-align: ${align.xs ? align.xs : align.default};`}
    color: ${({ color }) => color};
  }

  @media ${theme.responsiveCSSRules.sm} {
    &.display {
      ${({ fontSize }) =>
        fontSize && fontSize.sm
          ? `font-size: ${fontSize.sm};`
          : 'font-size: 5.6rem;'}
      ${({ lineHeight }) =>
        lineHeight && lineHeight.sm
          ? `line-height: ${lineHeight.sm};`
          : 'line-height: 7.6rem;'}
      ${({ align }) => align && align.sm && ` text-align: ${align.sm};`}
      color: ${({ color }) => color};
    }

    &.h1 {
      ${({ align }) => align && align.sm && ` text-align: ${align.sm};`}
      color: ${({ color }) => color};
    }

    &.h2 {
      ${({ fontSize }) =>
        fontSize && fontSize.sm
          ? `font-size: ${fontSize.sm};`
          : 'font-size: 2.8rem;'}
      ${({ lineHeight }) =>
        lineHeight && lineHeight.sm
          ? `line-height: ${lineHeight.sm};`
          : 'line-height: 3.8rem;'}
      ${({ align }) => align && align.sm && ` text-align: ${align.sm};`}
      color: ${({ color }) => color};
    }

    &.h3 {
      ${({ fontSize }) =>
        fontSize && fontSize.sm
          ? `font-size: ${fontSize.sm};`
          : 'font-size: 2.84em;'}
      ${({ lineHeight }) =>
        lineHeight && lineHeight.sm
          ? `line-height: ${lineHeight.sm};`
          : 'line-height: 3.4rem;'}
      ${({ align }) => align && align.sm && ` text-align: ${align.sm};`}
      color: ${({ color }) => color};
    }

    &.p {
      ${({ fontSize }) =>
        fontSize
          ? `font-size: ${fontSize.sm ? fontSize.sm : fontSize.default};`
          : 'font-size: 1.2rem;'}
      ${({ lineHeight }) =>
        lineHeight
          ? `line-height: ${
              lineHeight.sm ? lineHeight.sm : lineHeight.default
            };`
          : 'line-height: 2rem;'}
      font-weight: 400;
      margin: 0;
      ${({ align }) => align && align.sm && ` text-align: ${align.sm};`}
      color: ${({ color }) => color};
    }

    &.caption {
      ${({ fontSize }) =>
        fontSize
          ? `font-size: ${fontSize.sm ? fontSize.sm : fontSize.default};`
          : 'font-size: 1rem;'}
      ${({ lineHeight }) =>
        lineHeight
          ? `line-height: ${
              lineHeight.sm ? lineHeight.sm : lineHeight.default
            };`
          : 'line-height: 2rem;'}
      ${({ align }) => align && align.sm && ` text-align: ${align.sm};`}
      color: ${({ color }) => color};
    }
  }

  @media ${theme.responsiveCSSRules.md} {
    &.display {
      ${({ fontSize }) =>
        fontSize && fontSize.md
          ? `font-size: ${fontSize.md};`
          : 'font-size: 6.4rem;'}
      ${({ lineHeight }) =>
        lineHeight && lineHeight.md
          ? `line-height: ${lineHeight.md};`
          : 'line-height: 8.4rem;'}
      ${({ align }) => align && align.md && ` text-align: ${align.md};`}
      color: ${({ color }) => color};
    }

    &.h1 {
      ${({ align }) => align && align.md && ` text-align: ${align.md};`}
      color: ${({ color }) => color};
    }

    &.h2 {
      ${({ fontSize }) =>
        fontSize && fontSize.md
          ? `font-size: ${fontSize.md};`
          : 'font-size: 2.8rem;'}
      ${({ lineHeight }) =>
        lineHeight && lineHeight.md
          ? `line-height: ${lineHeight.md};`
          : 'line-height: 3.8rem;'}
      ${({ align }) => align && align.md && ` text-align: ${align.md};`}
      color: ${({ color }) => color};
    }

    &.h3 {
      ${({ fontSize }) =>
        fontSize && fontSize.md
          ? `font-size: ${fontSize.md};`
          : 'font-size: 2.4rem;'}
      ${({ lineHeight }) =>
        lineHeight && lineHeight.md
          ? `line-height: ${lineHeight.md};`
          : 'line-height: 3.4rem;'}
      ${({ align }) => align && align.md && ` text-align: ${align.md};`}
      color: ${({ color }) => color};
    }

    &.p {
      ${({ fontSize }) =>
        fontSize
          ? `font-size: ${fontSize.md ? fontSize.md : fontSize.default};`
          : 'font-size: 1.2rem;'}
      ${({ lineHeight }) =>
        lineHeight
          ? `line-height: ${
              lineHeight.md ? lineHeight.md : lineHeight.default
            };`
          : 'line-height: 2rem;'}
      font-weight: 400;
      margin: 0;
      ${({ align }) => align && align.md && ` text-align: ${align.md};`}
      color: ${({ color }) => color};
    }

    &.caption {
      ${({ fontSize }) =>
        fontSize
          ? `font-size: ${fontSize.md ? fontSize.md : fontSize.default};`
          : 'font-size: 1rem;'}
      ${({ lineHeight }) =>
        lineHeight
          ? `line-height: ${
              lineHeight.md ? lineHeight.md : lineHeight.default
            };`
          : 'line-height: 2rem;'}
      ${({ align }) => align && align.md && ` text-align: ${align.md};`}
      color: ${({ color }) => color};
    }
  }

  @media ${theme.responsiveCSSRules.lg} {
    &.display {
      ${({ fontSize }) =>
        fontSize && fontSize.lg
          ? `font-size: ${fontSize.lg};`
          : 'font-size: 8rem;'}
      ${({ lineHeight }) =>
        lineHeight && lineHeight.lg
          ? `line-height: ${lineHeight.lg};`
          : 'line-height: 10rem;'}
      ${({ align }) => align && align.lg && ` text-align: ${align.lg};`}
      color: ${({ color }) => color};
    }

    &.h1 {
      ${({ align }) => align && align.lg && ` text-align: ${align.lg};`}
      color: ${({ color }) => color};
    }

    &.h2 {
      ${({ fontSize }) =>
        fontSize && fontSize.sm
          ? `font-size: ${fontSize.sm};`
          : 'font-size: 3.2rem;'}
      ${({ lineHeight }) =>
        lineHeight && lineHeight.sm
          ? `line-height: ${lineHeight.sm};`
          : 'line-height: 5.2rem;'}
      ${({ align }) => align && align.lg && ` text-align: ${align.lg};`}
      color: ${({ color }) => color};
    }

    &.h3 {
      ${({ fontSize }) =>
        fontSize && fontSize.sm
          ? `font-size: ${fontSize.sm};`
          : 'font-size: 2.8rem;'}
      ${({ lineHeight }) =>
        lineHeight && lineHeight.sm
          ? `line-height: ${lineHeight.sm};`
          : 'line-height: 3.8rem;'}
      ${({ align }) => align && align.lg && ` text-align: ${align.lg};`}
      color: ${({ color }) => color};
    }

    &.p {
      ${({ fontSize }) =>
        fontSize
          ? `font-size: ${fontSize.lg ? fontSize.lg : fontSize.default};`
          : 'font-size: 1.4rem;'}
      ${({ lineHeight }) =>
        lineHeight
          ? `line-height: ${
              lineHeight.lg ? lineHeight.lg : lineHeight.default
            };`
          : 'line-height: 2.4rem;'}
      font-weight: 400;
      margin: 0;
      ${({ align }) => align && align.lg && ` text-align: ${align.lg};`}
      color: ${({ color }) => color};
    }

    &.caption {
      ${({ fontSize }) =>
        fontSize
          ? `font-size: ${fontSize.lg ? fontSize.lg : fontSize.default};`
          : 'font-size: 1.2rem;'}
      ${({ lineHeight }) =>
        lineHeight
          ? `line-height: ${
              lineHeight.lg ? lineHeight.lg : lineHeight.default
            };`
          : 'line-height: 2.4rem;'}
      ${({ align }) => align && align.lg && ` text-align: ${align.lg};`}
      color: ${({ color }) => color};
    }
  }
`;

export default Wrapper;
