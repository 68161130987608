import PropTypes from 'prop-types';

const Decathlon = ({ fill }) => {
  return (
    <svg
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0.324 1000 248.676"
    >
      <path d="M0 .324v248.003L1000 249V.324H0z" fill={fill} />
      <g fill="#fff">
        <path d="M399.832 59.812l-56.002 82.93c-5.825 8.777-16.043 14.082-26.737 14.082-17.415 0-31.83-14.31-31.83-31.576 0-17.521 14.415-31.804 31.952-31.804 1.738-.223 4.07-.466 5.697-.466 12.089 0 24.405 5.071 32.773 13.604l16.726-25.349c-13.01-13.355-32.301-21.903-51.598-21.903-36.022 0-69.018 29.726-69.018 65.674 0 35.736 29.514 64.99 65.547 64.99 17.425 0 34.851-7.371 46.94-19.592h38.577l.228 19.593h33.48V59.812h-36.734zm-15.321 82.013l18.348-25.555.228 25.555h-18.575zM500.709 189.993H467.24V90.21h-26.027V60.028l85.51-.218v30.4H500.71v99.783zM638.29 59.812l-33.468.008-.233 48.604h-39.515l-.25-48.614h-33.441v130.183h33.69V138.6h39.748v51.392h33.468V59.81zM906.481 59.812v60.581L860.47 59.812l-30.457.212-.23 47.48c-7.668-27.654-33.933-47.692-63.215-47.692-36.017 0-65.536 29.244-65.536 65.192 0 11.543 3.482 23.266 9.052 33.42h-33.155V59.812h-34.104v130.183h77.964v-18.216c12.317 11.3 29.048 18.216 45.78 18.216 29.28 0 55.085-19.593 63.215-47.253l.229 47.253h33.711v-63.353l45.764 63.353h30.436l.042-130.183h-33.478zm-139.913 98.384c-18.358 0-33.462-14.744-33.462-33.192 0-18.194 15.104-33.166 33.462-33.166 18.602 0 33.707 14.972 33.707 33.166 0 18.448-15.105 33.192-33.707 33.192M211.32 158.426v-18.22h35.917l-.064-31.783H211.32V91.84h39.59l-.068-32.026-76.227.212v44.709c-7.901-24.422-30.685-42.404-56.712-44.709l-57.868-.212v130.178h58.106c25.789-2.295 48.573-20.267 56.474-44.465v44.465h76.295l-.068-31.566h-39.52zm-73.66-13.599c-2.084 8.766-10.457 14.972-19.52 15.205H96.761V90.208h21.38c9.062 0 17.435 6.482 19.519 15.454 2.098 5.994 3.259 12.905 3.259 19.344 0 6.699-1.16 13.6-3.26 19.821" />
      </g>
    </svg>
  );
};

Decathlon.defaultProps = {
  fill: '#1482c2'
};

Decathlon.propTypes = {
  fill: PropTypes.string
};

export default Decathlon;
