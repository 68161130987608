import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';

import Decathlon from './Logos/Decathlon';
import Unii from './Logos/Unii';
import Portela from './Logos/Portela';
import WHO from './Logos/WHO';
import BaB from './Logos/BaB';
import PublicService from './Logos/PublicServ-ce';
import WGH from './Logos/WGH';
import FrenchGov from './Logos/FrenchGov';
import ReactIcon from './Logos/React';
import Redux from './Logos/Redux';
import AWS from './Logos/AWS';
import Terraform from './Logos/Terraform';
import CircleCI from './Logos/CircleCI';
import Figma from './Logos/Figma';
import Wordpress from './Logos/Wordpress';
import Shopify from './Logos/Shopify';
import Contentful from './Logos/Contentful';

const Icon = (props) => {
  const { type, link, fill, hoveredFill } = props;

  const [hovered, setHovered] = useState(false);

  const getLogo = (type) => {
    switch (type) {
      case 'decathlon':
        return <Decathlon fill={!hovered ? fill : hoveredFill} />;
      case 'unii':
        return <Unii />;
      case 'portela':
        return <Portela />;
      case 'who':
        return <WHO />;
      case 'bab':
        return <BaB />;
      case 'public-service':
        return <PublicService />;
      case 'wgh':
        return <WGH />;
      case 'fr-gov':
        return <FrenchGov />;
      case 'aws':
        return <AWS />;
      case 'react':
        return <ReactIcon />;
      case 'redux':
        return <Redux />;
      case 'terraform':
        return <Terraform />;
      case 'circleci':
        return <CircleCI />;
      case 'figma':
        return <Figma />;
      case 'wordpress':
        return <Wordpress />;
      case 'shopify':
        return <Shopify />;
      case 'contentful':
        return <Contentful />;
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Wrapper {...props}>
      {link ? (
        <a
          href={link}
          className="icon-link"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          target="_blank"
          rel="noreferrer"
        >
          {getLogo(type)}
        </a>
      ) : (
        getLogo(type)
      )}
    </Wrapper>
  );
};

Icon.defaultProps = {
  width: '150px',
  height: '150px',
  strokeWidth: '1'
};

Icon.propTypes = {
  type: PropTypes.string,
  link: PropTypes.string,
  fill: PropTypes.string,
  hoveredFill: PropTypes.string,
  stroke: PropTypes.string,
  hoveredStroke: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  strokeWidth: PropTypes.string,
  hoveredStrokeWidth: PropTypes.string
};

export default Icon;
