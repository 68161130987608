import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';
import { theme } from '../../Subatoms/Theme';
import Icon from '../../Atoms/Icon';

const IconButton = (props) => {
  const { onClick, icon, iconActive, active } = props;
  return (
    <Wrapper {...props} onClick={onClick}>
      {active ? (
        <Icon type={iconActive} stroke={theme.colors.white.default} />
      ) : (
        <Icon type={icon} stroke={theme.colors.white.default} />
      )}
    </Wrapper>
  );
};

IconButton.defaultProps = {
  icon: 'plus',
  bgColor: theme.colors.black.default,
  onClick: () => {},
  padding: `${theme.spacing * 2.5}px ${theme.spacing * 5}px`
};

IconButton.propTypes = {
  icon: PropTypes.oneOf(['plus', 'minus']),
  iconActive: PropTypes.oneOf(['plus', 'minus']),
  active: PropTypes.bool,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
  padding: PropTypes.string
};

export default IconButton;
