import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';

const BgImg = (props) => {
  return <Wrapper {...props} />;
};

BgImg.defaultProps = {
  imgURL: '',
  opacity: 0.5
};

BgImg.propTypes = {
  imgURL: PropTypes.string,
  opacity: PropTypes.number
};

export default BgImg;
