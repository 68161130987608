import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';

const Card = (props) => {
  const { bgImg, classes } = props;
  return (
    <Wrapper className={classes && `card-content ${[...classes]}`} {...props}>
      {bgImg && <div className="background"></div>}
      <div className="content-wrapper">{props.children}</div>
    </Wrapper>
  );
};

Card.defaultProps = {
  width: '100%',
  height: '100%',
  margin: 0
};

Card.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.array,
  width: PropTypes.object,
  minWidth: PropTypes.string,
  height: PropTypes.object,
  minHeight: PropTypes.string,
  borderRadius: PropTypes.string,
  margin: PropTypes.number,
  bgColor: PropTypes.string,
  bgImg: PropTypes.string,
  bgOpacity: PropTypes.number
};

export default Card;
