import React from 'react';
import PropTypes from 'prop-types';

// import { theme } from '../../Subatoms/Theme';
import Wrapper from './Wrapper';

const Badge = (props) => {
  return (
    <Wrapper {...props} className={`badge-${props.size}`}>
      {props.text}
    </Wrapper>
  );
};

Badge.defaultProps = {
  size: 'small'
};

Badge.propTypes = {
  text: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

export default Badge;
