import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import Wrapper from './Wrapper';
import getElementPosition from '../../Subatoms/Utils/scroll.js';
import { theme } from '../../Subatoms/Theme';
import Typography from '../../Subatoms/Typography';
import Flex from '../../Atoms/Flex';
import BgImg from '../../Atoms/BgImg';
import Logo from '../../Atoms/Logo';
import Card from '../../Molecules/Card';
import Badge from '../../Molecules/Badge';
import IconButton from '../../Molecules/IconButton';
import Icon from '../../Atoms/Icon';

const Slider = (props) => {
  const { slides } = props;
  const margin = theme.spacing * 8;

  const swiperRef = useRef(null);
  const swiperPlaying = useRef(false);
  const progressCircle = useRef(null);
  const progressContent = useRef(null);

  const [cardOpen, setCardOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  const handleCardClick = () => {
    const index = swiperRef.current.swiper.activeIndex;
    if (!cardOpen) {
      swiperRef.current.swiper.autoplay.pause();
      setCardOpen(true);
      setActiveIndex(index);
    } else {
      swiperRef.current.swiper.autoplay.resume();
      setCardOpen(false);
    }
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      const swiperPosition = getElementPosition(swiperRef);
      const offset = 200;
      if (
        swiperPosition.top - offset < 0 &&
        swiperPosition.bottom > 0 &&
        !swiperPlaying.current
      ) {
        swiperRef.current.swiper.autoplay.resume();
        swiperPlaying.current = true;
      } else if (
        swiperPosition.top > window.innerHeight &&
        swiperPlaying.current
      ) {
        swiperRef.current.swiper.autoplay.pause();
        swiperPlaying.current = false;
      } else if (swiperPosition.bottom < 0 && swiperPlaying.current) {
        swiperRef.current.swiper.autoplay.pause();
        swiperPlaying.current = false;
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    swiperRef.current.swiper.autoplay.pause();
  }, []);

  return (
    <Wrapper {...props}>
      <Swiper
        ref={swiperRef}
        slidesPerView={1}
        spaceBetween={margin}
        pagination={{
          type: 'progressbar'
        }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
          pauseOnMouseEnter: false
        }}
        modules={[Autoplay, Pagination]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
      >
        {slides.map((slide) => {
          return (
            <SwiperSlide key={slide.id}>
              <BgImg imgURL={slide.bgImg} opacity={1} />
              <Flex height={{ default: '100%' }} width={{ default: '100%' }}>
                <Flex
                  padding={{
                    default: `${theme.spacing * 2}px`,
                    md: `${theme.spacing * 4}px`
                  }}
                  justifyContent={{ default: 'flex-end' }}
                  alignItems={{ default: 'flex-end' }}
                  width={{ default: '100%' }}
                >
                  <Card
                    width={{
                      default: '0',
                      md: cardOpen ? '66%' : '565px'
                    }}
                    height={{
                      default:
                        cardOpen && activeIndex === slide.id
                          ? 'fit-content'
                          : 'fit-content'
                    }}
                    borderRadius="20px"
                    margin={theme.spacing * 3}
                    bgColor={theme.colors.white.default}
                    classes={cardOpen ? ['active'] : []}
                    cardTransition="all 500ms ease-in-out 1000ms"
                  >
                    <Flex
                      flexDirection={{ default: 'column' }}
                      justifyContent={{ default: 'space-between' }}
                      height={{ default: 'unset' }}
                      width={{ default: 'unset' }}
                      padding={{ default: `${theme.spacing * 3}px` }}
                    >
                      <Flex justifyContent={{ default: 'space-between' }}>
                        <Badge size="medium" text={slide.brand} />
                        <IconButton
                          icon="plus"
                          iconActive="minus"
                          active={cardOpen}
                          onClick={handleCardClick}
                        />
                      </Flex>
                      <Flex
                        flexDirection={{ default: 'column' }}
                        margin={{ default: '2rem 0' }}
                      >
                        <Flex
                          className={`slider-card-title${
                            cardOpen ? ' active' : ''
                          }`}
                        >
                          <Typography type="h3" text={slide.title} />
                        </Flex>
                        <Flex
                          flexDirection={{ default: 'column' }}
                          className={`slider-card-content${
                            cardOpen ? ' active' : ''
                          }`}
                        >
                          <Typography
                            type="p"
                            text={slide.description}
                            color={theme.colors.black.light}
                          />
                          <Flex
                            flexDirection={{ default: 'column' }}
                            margin={{ default: '2rem 0 0' }}
                          >
                            <Typography
                              type="caption"
                              text="Key Facts"
                              color={theme.colors.white.darker}
                              fontSize={{ default: '1rem' }}
                            />
                            {slide['keyFacts'].map((fact, i) => {
                              return (
                                <Flex
                                  key={i}
                                  alignItems={{ default: 'center' }}
                                  margin={{
                                    default: '0'
                                  }}
                                  width={{
                                    default: '100%'
                                  }}
                                >
                                  <Icon
                                    type="plus"
                                    width="16px"
                                    height="16px"
                                    bgColor={theme.colors.black.lighter}
                                    borderRadius="50%"
                                    stroke={theme.colors.white.default}
                                    strokeWidth="2"
                                    margin="0 1rem 0 0"
                                    padding=".1rem"
                                  />
                                  <Typography
                                    type="p"
                                    text={fact}
                                    color={theme.colors.black.light}
                                    fontSize={{ default: '1.2rem' }}
                                  />
                                </Flex>
                              );
                            })}
                          </Flex>
                          <Flex
                            flexDirection={{ default: 'column' }}
                            margin={{ default: '2rem 0 0' }}
                          >
                            <Typography
                              type="caption"
                              text="Tech"
                              color={theme.colors.white.darker}
                              fontSize={{ default: '1rem' }}
                            />
                            <Flex justifyContent={{ default: 'flex-start' }}>
                              {slide['stack'].map((tech, i) => {
                                return (
                                  <Flex
                                    key={i}
                                    margin={{
                                      default: '1rem 2rem 0 0'
                                    }}
                                    width={{
                                      default: '32px'
                                    }}
                                    height={{
                                      default: '32px'
                                    }}
                                    style={{
                                      transition: 'all 750ms ease-in-out'
                                    }}
                                  >
                                    <Logo
                                      type={tech}
                                      width="100%"
                                      height="100%"
                                    />
                                  </Flex>
                                );
                              })}
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>

                      <Flex
                        alignItems={{ default: 'center' }}
                        wrap={{ default: 'wrap' }}
                      >
                        {slide['services'].map((service, i) => {
                          return (
                            <Flex
                              key={i}
                              width={{ default: 'unset' }}
                              alignItems={{ default: 'center' }}
                              margin={{ default: '0 2rem 0 0' }}
                            >
                              <Typography
                                type="caption"
                                text={service}
                                color={theme.colors.white.darker}
                              />
                            </Flex>
                          );
                        })}
                      </Flex>
                    </Flex>
                  </Card>
                </Flex>
              </Flex>
            </SwiperSlide>
          );
        })}
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
      </Swiper>
    </Wrapper>
  );
};

Slider.defaultProps = {};

Slider.propTypes = {
  slides: PropTypes.array
};

export default Slider;
